/// <reference path="dt/jquery/jquery" />
/// <reference path="dt/bootstrap/bootstrap" />
/// <reference path="dt/velocity/velocity-animate.d.ts" />

declare var Drupal: any;

/**
 * See LikeDislikeService.js
 */
declare var LikeDislikeService: {
  vote: (entityId, entityType, voteType) => JQueryXHR;
};

export * from './modal';

let $ = jQuery;

$(document).ready(function() {
  /**
   * Sitemap
   */
  (() => {
    // Title
    let sitemap = $('#block-menu-menu-sitemap > .title')[0]
    if (sitemap)
      $('#sitemap-modal .control-panel-modal__title').html(sitemap.innerHTML);

    // Links
    let sitemapHtml = $('#block-menu-menu-sitemap .content')[0];
    if (sitemapHtml)
      $('#sitemap-modal .control-panel-modal__body').html(sitemapHtml.outerHTML);
  })();


  // Video link
  $('.article-video-link').click(function() {
    $('.field-type-video-embed-field').slideDown();
    $(this).fadeOut();
  });


  // Helpful
  (() => {
    $('.helpful__vote-btn').on('click', function() {
      let helpful = $(this).closest('.helpful');

      let voteType: string = $(this).data('vote');
      let entityId = helpful.data('entity-id');
      let entityType = 'node';

      LikeDislikeService.vote(entityId, entityType, voteType)
        .done((e: string) => {
          let votes = e.split('/');
          let likes = votes[0];
          let dislikes = votes[1];

          helpful.find('.helpful__vote').hide();

          if (voteType.toLowerCase() === 'dislike') {
            helpful.find('.helpful__form').show();
          }
          else {
            helpful.find('.helpful__msg').show();
          }
        })
    });

    $('.helpful__form').on('submit', function(e) {
      e.preventDefault();
      
      let helpful = $(this).closest('.helpful');
      let entityId = helpful.data('entity-id');
      $.ajax({
        url: `/node/${entityId}/feedback`,
        method: 'post',
        data: { comment: helpful.find('.helpful__text').val() }
      })
        .done(e => {
          helpful.find('.helpful__form').hide();
          helpful.find('.helpful__msg').show();
        });
    });
  })();
});