const DATA_KEY = 'mc-modal';
const $ = jQuery;
const duration = 200;

export default class Modal {
  constructor(private _$element: JQuery) {
  }

  /**
   * Shows modal of not visible.
   */
  show() {
    if (this._$element.is(':visible'))
      return;

    // Allow to close modal when clicked outside
    let docClicked = (e: JQueryEventObject) => {
      // Check that clicked outside of modal
      let target = $(e.target);
      if (target.closest(this._$element).length !== 0 && e.target != this._$element[0])
        return;

      $(document).off('click', docClicked);
      let modalLink = target.attr(`data-${DATA_KEY}`);
      let isOtherModalLink = modalLink != undefined && modalLink != ('#' + this._$element.attr('id'));
      this.hide(isOtherModalLink);
    };
    $(document).on('click', docClicked);

    this._$element
      .addClass('-open')
      .velocity('slideDown', duration);

    let backdrop = $(`.${DATA_KEY}-backdrop`);
    if (!backdrop.is(':visible'))
      backdrop
        .velocity('fadeIn', duration);

    this.getRelativeLinks()
      .addClass('-active');
  }

  /**
   * Hides modal if visible.
   */
  hide(keepBackdrop = false) {
    if (!this._$element.is(':visible'))
      return;

    this._$element
      .removeClass('-open')
      .velocity('slideUp', duration);

    if (!keepBackdrop)
      $(`.${DATA_KEY}-backdrop`)
        .velocity('fadeOut', duration);

    this.getRelativeLinks()
      .removeClass('-active');
  }
  
  /**
   * Gets relative links to modal by ID.
   */
  private getRelativeLinks(): JQuery {
    return $(`[data-${DATA_KEY}=#${this._$element.attr('id') }]`);
  }

  /**
   * Gets modal associated with element.
   * If no modal than it will be created.
   */
  static get(element: string) {
    let $el = $(element);
    let modal = $el.data(DATA_KEY);
    if (!modal) {
      modal = new Modal($el);
      $el.data(DATA_KEY, modal);
    }
    return modal;
  }
}


$(document).on('click', `[data-${DATA_KEY}]`, function(e) {
  e.preventDefault();
  Modal.get($(this).data(DATA_KEY)).show();
});